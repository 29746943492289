.page{
    padding:{
        top: 18vw;
        bottom: 18vw;
    }

    &-title{
        @extend .transition;
        color: #545454;
        font-size: 46px;
        padding: {
            left: 1rem;
            bottom: 12vw;
        }
    }

    .card{
        padding: 1rem;
        text-decoration: none;
        color: $black;

        &-item{
            @extend .transition;
            height: 22rem;
            background-color: #F6F6F6;  

            padding: 1rem {
                top: 12%;
            };         
            border: {
                color: $white;
                radius: 20px;
            };
        }

        hr{
            height: 4px;
            color: #100D30;
            opacity: inherit;
        }

        &-img-top{
            @extend .transition;
            width: 100px !important;
            padding: 1rem 1rem;
        }

        &-title{
            @extend .transition;
            font:{
                size: 25px;
                weight: 100;
            }
        }

        &-text{
            @extend .transition;
            font-size: 14px;
            line-height: 2;
        }
    }

    &-button{
        color: $orange;
        width: 100%;
        padding: 21px;

        @media(min-width: 768px){
            width: 58%;
        }

        @media(min-width: 992px){
            width: 50%;
        }
    }

         
    @media(max-width: 992px){
        &-title{
            font-size: 8vw;
        }
    }

    @media(min-width: 992px){

        padding:{
            top: 7vw;
            bottom: 7vw;
        }

        .card-item{
            background-color: $white;
        }

        &-title{
            font-size: 4vw;
            padding-bottom: 2vw;    
        }

        .card{
            &-item{
                padding: 0;
                height: auto;
            }

            &-img-top{
                width: 118px !important;
                padding-bottom: 0;
            }

            &-text{
                font-size: 14px;
            }
        }
    }

    @media(min-width: 1500px){
        padding:{
            top: 10vw;
            bottom: 10vw;
        }

        &-title{
            font-size: 3.4vw;
        }

        .card{
            &-title{
                font-size: 34px;
            }
            &-text{
                font-size: 16px;
            }
        }

        &-button{
            font-size: 1.4vw;
        }
    }
}