@import url('https://fonts.googleapis.com/css2?family=Baloo+Tammudu+2:wght@400;500;600;700;800&family=Montserrat:ital,wght@1,200&family=Poppins:wght@200;500&display=swap');

// Variables
@import 'variables';

// Bootstrap
// @import '~bootstrap/scss/bootstrap';

//imports
@import 'custom';
@import 'header';
@import 'page';
@import 'portfolio';
@import 'contacts';
@import 'footer';

*{
    font-family: $font-family-sans-serif;
}

body{
    background-color: $white;
}