// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Baloo Tammudu 2', cursive;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$white: #ffffff;
$blue: #1B7EAA;
$indigo: #6574cd;
$purple: #9561e2;
$purple-second: #53719E;
$purple-third : #a4abc8;
$pink: #f66d9b;
$red: #e3342f;
$orange: #D98555;
$yellow: #ffed4a;
$green: #38c172;
$green-second: #48BDB6;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$black: #545454;

.transition{
    transition: 0.5s all;
}