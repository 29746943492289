.contacts{
    @extend .header;
    background: {
        image: none;
        // repeat: no-repeat;
        // size: cover;
        // position: top;
        color: $green-second    ;
    }

    .container{
        padding: {
            top: 0;
            bottom: 0;
            left: var(--bs-gutter-x, 0.75rem);
            right: var(--bs-gutter-x, 0.75rem);
        }
    }

    .sombra{
        margin-top: -27vw;
        left: 0;

        @media(min-width: 992px){
            width: 36vw;
        }
    }
}