.header{
    color: $white;
    // background-color: $blue;
    background: {
            image: url("/images/slide.png");
            repeat: no-repeat;
            size: cover;
            color: transparent;
        }
    @extend .transition;
    z-index: 1;

    &-site{
        background: $black !important;
    }

    .navbar-light{
        @extend .transition;
        .navbar-brand img{
            @extend .transition;
            width: 60px;    
        }
        .navbar-toggler{
            right: 4%;
            @extend .transition;
        }
        .nav-link{
            color: $white !important;
            font: {
                size: 14px;
                weight: 400;
            };
        }
    }

    article{
       @extend .transition;
        padding:{
            top: 64%;
            bottom: 72%;
        }        

        h1{
            @extend .transition;
            font:{
                size: 5vw;
                weight: 100;
            }
        }

        p{
            font-size: 1.5vw;
           @extend .transition;
        }
    }

    .sombra{
        @extend .transition;
        right: 0;
        width: 57vw;
        margin-top: -49vw;
        position: absolute;
        overflow: hidden;
        
    }

    @media(min-width: 320px) and (height: 568px){
        article{
            padding:{
                top: 54%;
                bottom: 59%;
            }
        }
    }

    @media(min-width: 360px) and (height: 640px){
        article{
            padding:{
                top: 50%;
                bottom: 62%;
            }
        }
    }

    @media(min-width: 375px) and (height: 812px){
        article{
            padding:{
                top: 68%;
                bottom: 82%;
            }
        }
    }

    @media(min-width: 411px) and (height: 823px){
        article{
            padding:{
                top: 62%;
                bottom: 73%;
            }
        }
    }

    @media(width: 411px) and (height: 731px){
        article{
            padding:{
                top: 49%;
                bottom: 63%;
            }
        }
    }

    @media(min-width: 768px){
        article{
            padding:{
                top: 36%;
                bottom: 41%;
            }
        }
    }


    @media(max-width: 992px){
        article{

            h1{
                font-size: 11vw;
            }

            p{
                font-size: 3vw;
            }
        }
    }

    @media(min-width: 992px){
        

        .sombra{
            width: 40vw;
            margin-top: -35vw;
        }

        article{
            padding: {
                top: 16vw;
                bottom: 12vw;
            };

            h1{
                font-size: 5vw;
            }

            p{
                font-size: 1.5vw;
            }
        }
    }

    @media(min-width: 1500px){
        article{

            h1{
                font-size: 4vw;
            }

            p{
                font-size: 1.5vw;
            }
        }

        .sombra{
            margin-top: -34vw;
        }
    }
}