footer{
    @extend .header;
    @extend .transition;
    background: {
        color: $purple-third;
        image: none;
    }
    p{
        font-size: 20px;
    }

    .nav-item{
        &:nth-child(1){
            .nav-link img{
                width: 20px;
            }
        }

        &:nth-child(2){
            .nav-link img{
                width: 69px;
            }
        }

        @media(min-width: 768px){
            &:nth-child(1){
                .nav-link img{
                    width: 17px;
                }
            }

            &:nth-child(2){
                .nav-link img{
                    width: 59px;
                }
            }
        }
    }

    span{
        font-size: 11px;
    }

    .sombra{
        width: 87vw;
        margin-top: -66vw;
        mix-blend-mode: overlay;

        @media(min-width: 768px){
            width: 47vw;
            margin-top: -38vw;
        }

        @media(min-width: 992px){
            width: 33%;
            margin-top: -25%;
            right: 5%;
        }

        @media(min-width: 1200px){
            width: 30%;
            margin-top: -22%;
        }

        @media(min-width: 1500px){
            width: 23%;
            margin-top: -17.5%;
        }

        @media(min-width: 1700px){
            width: 21%;
            margin-top: -15.5%;
        }
    }

    .logo{
        width: 80px;
    }

    .page{
        @media(min-width: 768px){
            padding:{
                top: 12vw;
                bottom: 8vw;
            }
        }

        @media(min-width: 992px){
            padding:{
                top: 10vw;
                bottom: 7vw;
            }
        }

        @media(min-width: 1500px){
            padding:{
                top: 6vw;
                bottom: 5vw;
            }
        }
    }

    @media(min-width: 1400px){
        p{
            font-size: 25px;
        }
    }

    .btn-whatsapp {
        width: 60px;
        bottom: 96px;
        right: 6px;
    }
}