.portfolio{
    @extend .page;
    background-color: $black;

    .page-title{
        color: $white;
    }

    .card{
        @extend .transition;
        background-color: transparent;

        &-item{
            padding-top: 6%;
            z-index: 1;
            height: 19rem;

            @media(min-width: 992px){
                height: 15rem;
                padding-top: 0;
            }
        }

        &-background{
            @extend .transition;
            @extend .card;
            @extend .card-item;
            background-color: rgb(217, 133, 85, 88%);
            position: absolute;
            top: 4%;
            left: 3%;
            width: 94%;
            height: 92%;
            display: none;

            @media(min-width: 992px){
                top: 5%;
                height: 89%;
            }
        }

        &-title{
            color: $white;
            font:{
                size: 38px;
                weight: 400;
            }
            z-index: 1;
            display: none;
        }

        &:hover{
            .card-title{
                display: block;
            }
            .card-background{
                display: flex;
            }
        }
    }
    
}